@import '~antd/dist/antd.css';

#root {
  min-height: 100vh;
}

#root h1 {
  font-size: 2em;
}

#root h2 {
  font-size: 1.5em;
}

.ant-card.ant-card-bordered.f-form > .ant-card-head {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #ffff;
}
